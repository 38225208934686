<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    width="60%"
    append-to-body
    top="7vh"
  >
    <!-- editData：{{editData}} -->
    <!-- isActivity:{{isActivity}} -->
    <el-form ref="formData" :model="formData" label-width="120px">
      <div class="dia-tit not-title">
        <i></i>
        <span v-text="topicDialogTitle"></span>
      </div>
      <el-form-item label="题库标题：">
        <el-input v-model="formData.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <!-- <el-form-item label="开放时间：">
        <el-date-picker
          v-model="formData.time"
          type="datetimerange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00']">
        </el-date-picker>
      </el-form-item> -->
      <el-form-item label="统计明细：">
        <el-radio-group v-model="formData.isShow" size="mini">
          <el-radio-button :label="1">展示</el-radio-button>
          <el-radio-button :label="2">不展示</el-radio-button>
        </el-radio-group>
        <span style="display: inline-block;color: #ccc;margin-left: 25px;">（题库控制统计明细是否展示）</span>
      </el-form-item>

      <el-form-item label="题库语言：" prop="langmask">
        <!-- 级联选择器 -->
        <!-- formData.langmask：{{formData.langmask}} -->
        <el-select v-model="formData.langmask" multiple placeholder="请选择" @change="updateView">
          <el-option
            v-for="item in langmaskOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-row>
        <div class="dia-tit not-title">
          <i></i>
          <span>选择题目</span>
        </div>
        <div style="display: flex;">
          <el-form-item label="已选题目：">
            <draggable v-model="formData.seleTopic" @update="datadragEnd" :options="{animation:200}">
              <el-tag
                style="cursor: pointer;"
                v-for="(tag, index) in formData.seleTopic"
                :key="index"
                closable
                type="info"
                @close="tagDel(index)"
              >
                <el-tooltip class="item" effect="dark" :content="tag.topicTitle" placement="top">
                  <span>{{tag.topicId}}</span>
                </el-tooltip>
              </el-tag>
            </draggable>
          </el-form-item>
          <el-button
            size="small"
            style="height: 35px;"
            type="info"
            @click="sortQuestionLibrary"
            :disabled="topicDialogTitle === '新增题库'"
          >排 序</el-button>
        </div>
      </el-row>
      <el-row>
        <el-form-item label="待选题目："></el-form-item>
      </el-row>
    </el-form>

    <el-row class="label-item-left">
      <el-input placeholder="请输入内容" v-model="queryData.inputVal" style="width: 350px;margin-right: 20px" @keyup.enter.native="search">
        <el-select style="width: 120px" v-model="queryData.select" slot="prepend">
          <el-option label="问题标题" value="1"></el-option>
          <el-option label="问题id" value="2"></el-option>
        </el-select>
      </el-input>
      <el-select
        v-model="queryData.seleTag"
        multiple
        placeholder="请选择"
        style="width: 200px;margin-right: 20px;"
        @change="handleSeleTag"
      >
        <el-option v-for="item in tagList" :key="item.id" :label="item.title" :value="item.id"></el-option>
      </el-select>
      <el-button type="info" @click="search" size="small">筛 选</el-button>
      <el-button type="primary" @click="reset" size="small">重 置</el-button>
    </el-row>

    <el-row class="label-item-left">
      <el-table
        ref="multipleTable"
        :data="dataList"
        border
        fit
        highlight-current-row
        class="tb-list"
        @selection-change="handleSeleChange"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column label="问题ID" align="center" width="200" prop="problem_id"></el-table-column>
        <el-table-column label="问题标题" align="left" prop="title"></el-table-column>
        <el-table-column label="标签" align="center">
          <template slot-scope="scope">
            <template v-if="!scope.row.tagList || scope.row.tagList.length === 0">
              <span>无</span>
            </template>
            <template v-else>
              <span v-for="item of scope.row.tagList" :key="item.id">
                <template v-if="Boolean(item.groupInfo)">
                  <span v-text="item.title" class="tag" :style="'backgroundColor'+':'+item.groupInfo.color"></span>
                </template>
                <template v-else>
                  <span v-text="item.title" class="tag" :style="'backgroundColor'+':'+ '#ccc'"></span>
                </template>
              </span>
            </template>
          </template>
          <!-- <template slot-scope="scope">
            <el-row v-if="scope.row.tag_titles">
              <span
                class="tag-span"
                v-for="(item, index) of jointArr(scope.row.tag_titles)"
                :key="index"
              >{{ item }}</span>
            </el-row>
            <el-row v-else>无</el-row>
          </template> -->
        </el-table-column>
        <el-table-column label="添加" align="center">
          <template slot-scope="scope">
            <span
              class="btn-span"
              v-if="scope.row.choosetype == 0"
              @click="addQues(scope.row, scope.$index)"
            >添加</span>
            <span class="btn-hasspan" v-if="scope.row.choosetype == 1">已添加</span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row class="label-item-left">
      <el-dropdown trigger="click" class="tb-dropdown" style="margin-top: 20px;">
        <el-button type="info" size="medium">
          批量操作
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu style="padding: 10px;" slot="dropdown">
          <el-dropdown-item @click.native="enableAccount"><i class="el-icon-circle-plus-outline"></i>批量添加</el-dropdown-item>
          <el-dropdown-item @click.native="disabledAccount"><i class="el-icon-delete"></i>批量删除</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-row>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

    <el-row slot="footer">
      <el-button type="primary" @click="addtopic" class="dialog-btn dialog-left-btn">保 存</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import draggable from 'vuedraggable'
import { langmask } from '@/utils/langmask'
import { getTags } from '@/api/research/tag'

import { getQuestionList } from '@/api/research/question'
import { getQuestionByLibraryId, addQuestionLibrary, updateQuestionLibrary, sortQuestionLibrary } from '@/api/research/quesBank'

export default {
  components: { draggable },
  props: {
    show: {
      default: false,
      type: Boolean
    },
    editData: {
      default: () => {
        return {}
      },
      type: Object
    },
    isActivity: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      topicDialogTitle: '',
      queryData: {
        inputVal: '',
        select: '1',
        seleTag: '',
        tagId: ''
      },
      tagList: [], // 已启用的标签
      formData: {
        title: [],
        time: '',
        isShow: 1,
        seleTopic: []
      },
      dataList: [],
      editDataListLength: 0,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      multipleSelection: [],
      contest_id: '',
      rules: {
        title: [
          { required: true, message: '课程名称不能为空', trigger: 'blur' },
          { max: 50, message: '字数限制在50个字内', trigger: 'blur' }
        ],
      },
      langmaskOptions: langmask,
      langmask: ''
    }
  },
  methods: {
    updateView () {
      this.$forceUpdate()
    },
    openDialog () {
      this.formData = {
        title: '',
        time: [],
        isShow: 1,
        seleTopic: []
      }
      this.queryData = {
        inputVal: '',
        select: '1',
        seleTag: '',
        tagId: ''
      }
      if (this.editData === null) {
        this.topicDialogTitle = '新增题库'
      } else {
        this.topicDialogTitle = '编辑题库'
        this.formData.title = this.editData.title
        if (Boolean(this.editData.startTime) && Boolean(this.editData.endTime)) {
          this.formData.isShow = Number(this.editData.census_control)
          this.formData.time.push(this.editData.startTime.substring(0, 19))
          this.formData.time.push(this.editData.endTime.substring(0, 19))
        }
        this.formData.langmask = this.editData.langmask.split(',')
        this.contest_id = this.editData.contest_id
        this.queryQuesById(this.editData.contest_id)
      }
      this.getQuestionList()
      this.getTags()
    },

    async datadragEnd (evt) {
      evt.preventDefault()

      // 遍历数组,将索引值赋值到对应的sort_order上面,完成排序
      // console.log('拖动前的索引 :' + JSON.stringify(this.formData.seleTopic))
    },

    // 根据题库id获取题目
    queryQuesById (contestID) {
      getQuestionByLibraryId({
        contest_id: contestID
      }).then(res => {
        if (res.state === 'success') {
          for (let i = 0; i < res.body.length; i++) {
            this.formData.seleTopic.push({
              topicId: res.body[i].problem_id,
              topicTitle: res.body[i].title
            })
          }
          this.editDataListLength = this.formData.seleTopic.length
          for (let i = 0; i < this.formData.seleTopic.length; i++) {
            for (let j = 0; j < this.dataList.length; j++) {
              if (this.formData.seleTopic[i].topicId === this.dataList[j].problem_id) {
                this.dataList[j].choosetype = 1
              }
            }
          }
        }
      })
    },

    // 获取题目列表
    async getQuestionList () {
      const res = await getQuestionList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        defunct: 'N',
        title: this.queryData.select === '1' ? this.queryData.inputVal : '',
        problem_id: this.queryData.select === '2' ? this.queryData.inputVal : '',
        tag_ids: this.queryData.tagId
      })
      this.pagination.total = res.body.total
      this.dataList = res.body.list
      for (let i = 0; i < res.body.list.length; i++) {
        res.body.list[i].choosetype = 0
      }
      this.total = res.body.total
      this.dataList = res.body.list
      for (let i = 0; i < this.formData.seleTopic.length; i++) {
        for (let j = 0; j < this.dataList.length; j++) {
          if (this.formData.seleTopic[i].topicId === this.dataList[j].problem_id) {
            this.dataList[j].choosetype = 1
          }
        }
      }
    },

    // 获取启用的标签
    async getTags () {
      const res = await getTags(
        {
          pageNum: 1,
          pageSize: 10000,
          businessType:'oj'
        }
      )
      this.tagList = res.body
    },

    jointArr (row) {
      let tagArr = []
      tagArr = row.split(',')
      return tagArr
    },

    // 重置
    reset () {
      this.queryData = {
        inputVal: '',
        select: '1',
        seleTag: '',
        tagId: ''
      }
    },

    // 批量添加数据
    enableAccount () {
      for (let i = 0; i < this.multipleSelection.length; i++) {
        let addflag = true
        for (let j = 0; j < this.formData.seleTopic.length; j++) {
          if (
            this.multipleSelection[i].problem_id === this.formData.seleTopic[j].topicId
          ) {
            addflag = false
            break
          }
        }
        if (addflag) {
          for (let j = 0; j < this.dataList.length; j++) {
            if (
              this.multipleSelection[i].problem_id ===
              this.dataList[j].problem_id
            ) {
              this.dataList[j].choosetype = 1
            }
          }
          this.formData.seleTopic.push({
            topicId: this.multipleSelection[i].problem_id,
            topicTitle: this.multipleSelection[i].title
          })
        }
        addflag = true
      }
      this.$refs.multipleTable.clearSelection()
    },

    // 批量删除
    disabledAccount () {
      let deleCurrnt = 0
      for (let i = 0; i < this.multipleSelection.length; i++) {
        let addflag = true
        for (let j = 0; j < this.formData.seleTopic.length; j++) {
          if (
            this.multipleSelection[i].problem_id === this.formData.seleTopic[j].topicId
          ) {
            addflag = false
            deleCurrnt = j
            break
          }
        }
        if (!addflag) {
          for (let k = 0; k < this.dataList.length; k++) {
            if (
              this.multipleSelection[i].problem_id ===
              this.dataList[k].problem_id
            ) {
              this.dataList[k].choosetype = 0
            }
          }
          this.formData.seleTopic.splice(deleCurrnt, 1)
        }
        addflag = true
      }
      this.$refs.multipleTable.clearSelection()
    },

    // 选择标签
    handleSeleTag (tagId) {
      let problemIds = ''
      problemIds = tagId[0]
      for (let i = 1; i < tagId.length; i++) {
        problemIds = problemIds + ',' + tagId[i]
      }
      if (problemIds === undefined) {
        this.queryData.tagId = null
      } else {
        this.queryData.tagId = problemIds
      }
    },

    // 查询题目
    search () {
      if (this.queryData.select === '2') {
        let reg = /^[0-9]*$/
        if (!reg.test(this.queryData.inputVal)) {
          window.$msg('请输入正确的id', 2)
          return false
        }
      }
      this.pagination.currentPage = 1
      this.getQuestionList()
    },

    // 新增题目
    addtopic () {
      let problemThemb
      if (this.formData.title === '') {
        window.$msg('题库标题不能为空', 2)
        return false
      }

      if (this.formData.seleTopic.length === 0) {
        window.$msg('请为题库添加题目后在提交', 2)
        return false
      } else {
        problemThemb = this.formData.seleTopic[0].topicId
        for (let i = 1; i < this.formData.seleTopic.length; i++) {
          problemThemb = problemThemb + ',' + this.formData.seleTopic[i].topicId
        }
      }
      if (this.editData === null) {
        this.langmask = ''
        this.formData.langmask.forEach((element, index) => {
          if (index === this.formData.langmask.length - 1) {
            this.langmask += element
          } else {
            this.langmask += element + ','
          }
        })
        addQuestionLibrary({
          title: this.formData.title,
          problem_ids: problemThemb,
          // startTime: this.formData.time ? this.formData.time[0] : null,
          // endTime: this.formData.time ? this.formData.time[1] : null,
          census_control: this.formData.isShow,
          langmask: this.langmask
        }).then(res => {
          if (res.state === 'success') {
            window.$msg('新增成功')
            this.close()
            if (!this.isActivity) { this.$emit('getQuesibraryList') }
          }
        })
      } else {
        this.langmask = ''
        this.formData.langmask.forEach((element, index) => {
          if (index === this.formData.langmask.length - 1) {
            this.langmask += element
          } else {
            this.langmask += element + ','
          }
        })
        updateQuestionLibrary({
          contest_id: this.contest_id,
          title: this.formData.title,
          problem_ids: problemThemb,
          // startTime: this.formData.time ? this.formData.time[0] : null,
          // endTime: this.formData.time ? this.formData.time[1] : null,
          census_control: this.formData.isShow,
          langmask: this.langmask,
        }).then(res => {
          if (res.state === 'success') {
            window.$msg('编辑成功')
            this.close()
            if (!this.isActivity) { this.$emit('getQuesibraryList') }
            else {this.$emit('getQuesibraryOne', this.contest_id)}
          }
        })
      }
    },

    // 新增题目
    sortQuestionLibrary () {
      let problemThemb
      if (this.formData.seleTopic.length < 2) {
        window.$msg('题目排序最少为两条数据', 2)
        return false
      } else {
        problemThemb = this.formData.seleTopic[0].topicId
        for (let i = 1; i < this.formData.seleTopic.length; i++) {
          problemThemb =
            problemThemb + ',' + this.formData.seleTopic[i].topicId
        }
      }
      sortQuestionLibrary({
        contest_id: this.contest_id,
        problem_ids: problemThemb
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('排序成功')
        }
      })
    },

    // 关闭
    close () {
      this.formData.seleTopic = []
      this.formData.title = ''
      for (let j = 0; j < this.dataList.length; j++) {
        this.dataList[j].choosetype = 0
      }
      this.$refs.multipleTable.clearSelection()
      this.multipleSelection = []
      this.$emit('close')
    },

    tagDel (index) {
      this.$confirm('是否删除当前题目', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          for (let i = 0; i < this.dataList.length; i++) {
            if (this.formData.seleTopic[index].topicId === this.dataList[i].problem_id) {
              this.dataList[i].choosetype = 0
            }
          }
          this.formData.seleTopic.splice(index, 1)
          window.$msg('删除成功')
          this.getQuestionList()
        })
        .catch(() => {
          return false
        })
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getQuestionList()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getQuestionList()
    },

    // 添加题
    addQues (row, index) {
      this.dataList[index].choosetype = 1
      this.formData.seleTopic.push({
        topicId: row.problem_id,
        topicTitle: row.title
      })
    },

    handleSeleChange (val) {
      this.multipleSelection = val
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__body {
  padding: 0 !important;
}
.tag {
  display: inline-block;
  color: #fff;
  border-radius: 17px 20px 0px 17px;
  padding: 3px 15px;
  margin-right: 10px;
  margin-bottom: 8px;
}
@import "../../../style/dialog.scss";
</style>
